<template>
  <el-table :data="listOrganizations">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Tên" prop="summary" width="350">
      <template slot-scope="scope">
        <a href="javascript:;" @click="handleDecentralization(scope.row)">{{ scope.row.summary }}</a>
      </template>
    </el-table-column>
    <el-table-column label="Mô tả" prop="description"></el-table-column>
    <el-table-column label="Ngày tạo" prop="created_at" width="120"></el-table-column>
    <el-table-column label="Thao tác" width="150" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Chỉnh sửa" placement="top">
          <el-button
            icon="el-icon-edit"
            type="primary"
            circle
            size="small"
            @click="handleUpdateOrganization(scope.row)"
          ></el-button>
        </el-tooltip>
        <el-tooltip content="Chi tiết phân quyền" placement="top">
          <el-button
            icon="el-icon-position"
            type="primary"
            circle
            size="small"
            @click="handleDecentralization(scope.row)"
          ></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa user" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="handleDeleteOrganization(scope.row.id)"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { deleteOrganization } from '@/services/organizations'
export default {
  name: 'ListUserStaff',
  props: {
    listOrganizations: Array
  },
  methods: {
    handleDeleteOrganization(id) {
      this.$confirm('Bạn có chắc chắn muốn xóa tổ chức này?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(async () => {
        await deleteOrganization(id).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Xóa tổ chức thành công'
          })
          this.$emit('reload')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Xóa tổ chức thất bại'
          })
        })
      }).catch(() => {})
    },

    handleUpdateOrganization(row) {
      this.$emit('updateOrganization', row)
    },

    handleDecentralization(row) {
      this.$router.push({ name: 'OrganizationsInfo', params: { id: row.id }})
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: #1174d4;
}
</style>
