import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getListOrganizations(params) {
  return request({
    url: ENDPOINT.ORGANIZATIONS,
    method: 'get',
    params
  })
}

export function createOrganization(data) {
  return request({
    url: ENDPOINT.ORGANIZATIONS,
    method: 'post',
    data
  })
}

export function deleteOrganization(id) {
  return request({
    url: ENDPOINT.ORGANIZATIONS,
    method: 'delete',
    params: {
      id: id
    }
  })
}
export function updateOrganization(data) {
  return request({
    url: ENDPOINT.ORGANIZATIONS,
    method: 'patch',
    data
  })
}

export function getOrganizationById(params) {
  return request({
    url: ENDPOINT.ORGANIZATIONS,
    method: 'get',
    params
  })
}

export function addMember(data) {
  return request({
    url: `${ENDPOINT.ORGANIZATIONS}/add-member`,
    method: 'post',
    data
  })
}

export function removeMember(data) {
  return request({
    url: `${ENDPOINT.ORGANIZATIONS}/remove-member`,
    method: 'patch',
    data
  })
}

export function addOwner(data) {
  return request({
    url: `${ENDPOINT.ORGANIZATIONS}/add-owner`,
    method: 'patch',
    data
  })
}

export function addAdmin(data) {
  return request({
    url: `${ENDPOINT.ORGANIZATIONS}/add-admin`,
    method: 'patch',
    data
  })
}

export function appointOwner(data) {
  return request({
    url: `${ENDPOINT.ORGANIZATIONS}/appoint-owner`,
    method: 'patch',
    data
  })
}

