<template>
  <div class="organizations">
    <div class="user-page shared-index">
      <el-row class="table-title" type="flex" align="center" justify="space-between">
        <div class="table-title__left">
          <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
            <el-tab-pane name="organizations">
              <div slot="label">
                Danh sách tổ chức
                <span>({{ total }})</span>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="small"
            @click="createVisible = true"
          >Thêm</el-button>
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            size="small"
            @click="handleReloadData"
          >Làm mới</el-button>
        </div>
        <div class="table-title__right">
          <el-input
            v-model="searchText"
            suffix-icon="el-icon-search"
            placeholder="Tìm kiếm theo tên, email"
            size="small"
            @input="handleSearch"
          ></el-input>
        </div>
      </el-row>
      <div v-loading="callingAPI" class="table">
        <list-organizations
          :list-organizations="listOrganizations"
          @reload="loadData"
          @updateOrganization="handleUpdateOrganization"
        ></list-organizations>
      </div>
      <el-row type="flex" align="middle" justify="end" class="pagination">
        <el-pagination
          :current-page.sync="page"
          :page-sizes="[10, 15, 20, 30]"
          :page-size.sync="limit"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleChangeSize"
          @current-change="handleChangePage"
        ></el-pagination>
      </el-row>
      <create-organization-popup
        :visible="createVisible"
        @close="createVisible = false"
        @reload="loadData"
      ></create-organization-popup>
      <update-organization-popup
        :visible="updateVisible"
        :organization="selectedOrganization"
        @close="updateVisible = false"
        @reload="loadData"
      ></update-organization-popup>
    </div>
    <all-users />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getListOrganizations } from '@/services/organizations'
import ListOrganizations from './components/List.vue'
import CreateOrganizationPopup from './components/popups/Create.vue'
import UpdateOrganizationPopup from './components/popups/Update.vue'
import AllUsers from './components/AllUsers.vue'

export default {
  name: 'Organizations',
  components: {
    ListOrganizations,
    CreateOrganizationPopup,
    UpdateOrganizationPopup,
    AllUsers
  },
  data() {
    return {
      callingAPI: false,
      activeTab: 'organizations',
      searchText: '',
      limit: 10,
      total: 10,
      page: 1,
      listOrganizations: [],
      createVisible: false,
      updateVisible: false,
      selectedOrganization: {}
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  watch: {
    language: 'loadData'
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.callingAPI = true
      this.createVisible = false
      this.updateVisible = false
      const params = {
        page: this.page,
        per_page: this.limit,
        search_text: this.searchText
      }
      await getListOrganizations(params).then((response) => {
        this.listOrganizations = response.result
        this.total = response.total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },

    handleUpdateOrganization(organization) {
      this.selectedOrganization = organization
      this.updateVisible = true
    },

    handleReloadData() {
      this.searchText = ''
      this.page = 1
      this.loadData()
    },

    handleChangePage(page) {
      this.page = page
      this.loadData()
    },

    handleChangeTab() {
      this.page = 1
      this.loadData()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.loadData()
    },

    handleSearch(text) {
      this.searchText = text
      this.page = 1
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
