<template>
  <el-dialog
    top="80px"
    width="600px"
    class="create-user-popup"
    title="Cập nhật thông tin tổ chức"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Tên tổ chức" prop="summary">
        <el-input v-model="form.summary" :disabled="callingAPI" placeholder="Nhập tên tổ chức"></el-input>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input
          v-model="form.description"
          :disabled="callingAPI"
          placeholder="Nhập mô tả tổ chức"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateOrganization">Cập nhật</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateOrganization } from '@/services/organizations'
import { orgContentIds } from '@/utils/filters'
export default {
  name: 'UpdateOrganization',
  props: {
    visible: Boolean,
    organization: Object
  },
  data() {
    return {
      callingAPI: false,
      form: {
        summary: '',
        description: ''
      },
      rules: {
        summary: [
          { required: true, message: 'Tên tổ chức không được để trống', trigger: ['blur', 'change'] }
        ],
        description: [
          { required: true, message: 'Mô tả không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  watch: {
    'visible': 'loadData'
  },
  methods: {
    loadData() {
      if (this.visible) {
        this.form = {
          ...this.organization
        }
      }
    },
    handleUpdateOrganization() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          const data = {
            ...this.form,
            id: this.organization.id,
            user_ids: orgContentIds('users', this.organization),
            place_ids: orgContentIds('places', this.organization)
          }
          updateOrganization(data).then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Cập nhật tổ chức thành công'
            })
            this.$refs.form.resetFields()
            this.$emit('reload')
            this.callingAPI = false
          }).catch(() => {
            this.$notify.error({
              title: 'Thất bại',
              message: 'Cập nhật thông tin tổ chức thất bại.'
            })
            this.callingAPI = false
          })
        }
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
