<template>
  <div :class="type">
    <shared-user :type="type" :titles="titles" :staff-type="staffType" />
  </div>
</template>

<script>
import SharedUser from '@/components/user/index'

export default {
  name: 'AllOrgUsers',
  components: {
    SharedUser
  },
  data() {
    return {
      type: 'staff',
      staffType: 'moderator',
      titles: [
        { label: 'Danh sách tài khoản của các tổ chức', value: 'moderator' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
